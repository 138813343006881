






















































































































































































import Vue from 'vue'
import {
  BOverlay,
  BAlert,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import { tinyOptions } from '@core/mixins/tinyOptions'
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { appGroupListTemplate } from '@/store/modules/app-group-list-templates/app-group-list-templates-item'
import SaveGroupListTemplateModal
  from '@/views/admin/group-list-templates/group-list-template-item/save-group-list-template-modal/SaveGroupListTemplateModal.vue'

export default Vue.extend({
  name: 'GroupListTemplateItem',
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BForm,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    Editor,
    vSelect,

    AvailablePlaceholder,
    SaveGroupListTemplateModal,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions],
  data() {
    return {
      required,
    }
  },
  computed: {
    isLoading() {
      return appGroupListTemplate.getters.getIsLoading
    },
    template() {
      return appGroupListTemplate.getters.getTemplate
    },
    isButtonDisabled() {
      return appGroupListTemplate.getters.getIsButtonDisabled
    },
    templateId() {
      return appGroupListTemplate.getters.getTemplateId
    },
    groupTypeOptions() {
      return appGroupListTemplate.getters.getGroupTypeOptions
    },
    groupTypePlaceholders() {
      return appGroupListTemplate.getters.getGroupTypePlaceholders
    },
    selectedGroupTypePlaceholder: {
      get() {
        return appGroupListTemplate.getters.getSelectedGroupTypePlaceholder
      },
      set(val: string) {
        appGroupListTemplate.mutations.SET_SELECTED_GROUP_TYPE_PLACEHOLDER(val)
      },
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'template.group_type_id': function (val) {
      if (val) {
        this.fetchGroupTypePlaceholders()
      }
    },
    selectedGroupTypePlaceholder(val) {
      if (val) {
        this.insertValueToEditor(val)
        this.selectedGroupTypePlaceholder = null
      }
    },
  },
  async created() {
    appGroupListTemplate.mutations.SET_TEMPLATE_ID(router.currentRoute.query.id)
    this.setGroupListTemplate()
    await this.fetchGroupTypes()
  },
  methods: {
    async setGroupListTemplate() {
      if (this.templateId) {
        await appGroupListTemplate.actions.fetchGroupListTemplate(this.templateId)
      } else {
        appGroupListTemplate.mutations.INIT_TEMPLATE()
      }
    },
    async saveChanges() {
      return new Promise((resolve, reject) => {
        (this.$refs.groupListTemplateRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            if (this.templateId) {
              this.updateTemplate()
            } else {
              this.createTemplate()
            }
          } else {
            reject()
          }
        })
      })
    },
    async updateTemplate() {
      await appGroupListTemplate.actions.updateTemplate()
      this.$router.push({ name: 'admin-group-list-templates' })
    },
    async createTemplate() {
      await appGroupListTemplate.actions.createGroupListTemplate()
      this.$router.push({ name: 'admin-group-list-templates' })
    },
    insertValueToEditor(val: string) {
      getTinymce().activeEditor.execCommand('mceInsertContent', false, val)
    },
    async fetchGroupTypes() {
      await appGroupListTemplate.actions.fetchGroupTypes()
    },
    async fetchGroupTypePlaceholders() {
      await appGroupListTemplate.actions.fetchGroupTypePlaceholders()
    },
    openSaveAsModal() {
      return new Promise((resolve, reject) => {
        (this.$refs.groupListTemplateRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            this.$bvModal.show('save-group-list-template-modal')
          } else {
            reject()
          }
        })
      })
    },
  },
})
