<template>
  <ValidationObserver
    ref="saveGroupListTemplateModal"
  >
    <b-modal
      id="save-group-list-template-modal"
      ref="save-group-list-template-modal"
      :title="title"
      centered
      no-close-on-backdrop
      :ok-title="title"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @ok.prevent="save"
      @show="setData"
    >
      <b-form-group
        label="Group List Template Title"
      >
        <ValidationProvider
          #default="{ errors }"
          name="Group List Template Title"
          rules="required"
        >
          <b-form-input
            v-model="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { appGroupListTemplate } from '@/store/modules/app-group-list-templates/app-group-list-templates-item'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    groupListTemplateName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      required,
    }
  },
  computed: {
    title() {
      return 'Save As'
    },
  },
  watch: {
    groupListTemplateName(val) {
      this.name = val
    },
  },
  methods: {
    setData() {
      this.name = this.groupListTemplateName
    },
    save() {
      return new Promise((resolve, reject) => {
        this.$refs.saveGroupListTemplateModal.validate().then(success => {
          if (success) {
            resolve(true)
            appGroupListTemplate.mutations.SET_NEW_NAME(this.name)
            appGroupListTemplate.actions.saveAsGroupListTemplate()
            this.$refs['save-group-list-template-modal'].toggle('#toggle-btn')
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style>

</style>
