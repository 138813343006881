import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import {
  IGroupListTemplate, GroupListTemplateItemState, IGroupTypeOption, IGroupTypelaceholder,
} from './types'
import {
  createGroupListTemplate, fetchGroupListTemplate, fetchGroupTypePlaceholders, updateGroupListTemplate,
} from '@/api/groupListTemplates'
import { id } from '@/types'
import { fetchGroupTypesForSelect } from '@/api/groupTypes'

// eslint-disable-next-line import/prefer-default-export
export const appGroupListTemplate = createModule(
  'appGroupListTemplate',
  {
    namespaced: true,
    state: {
      isLoading: false,
      templateId: null,
      template: {
        name: '',
        body: '',
        program_id: '',
        group_type_id: '',
      },
      groupTypeOptions: [],
      groupTypePlaceholders: [],
      selectedGroupTypePlaceholder: null,
      isButtonDisabled: false,
      new_name: '',
    } as GroupListTemplateItemState,
    getters: {
      getIsLoading: state => state.isLoading,
      getTemplateId: state => state.templateId,
      getTemplate: state => state.template,
      getGroupTypeOptions: state => state.groupTypeOptions,
      getGroupTypePlaceholders: state => state.groupTypePlaceholders,
      getSelectedGroupTypePlaceholder: state => state.selectedGroupTypePlaceholder,
      getIsButtonDisabled: state => state.isButtonDisabled,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_TEMPLATE(state, val: IGroupListTemplate) {
        state.template = val
      },
      SET_TEMPLATE_ID(state, val: any) {
        state.templateId = val
      },
      SET_GROUP_TYPE_OPTIONS(state, val: IGroupTypeOption[]) {
        state.groupTypeOptions = val
      },
      SET_GROUP_TYPE_PLACEHOLDERS(state, val: IGroupTypelaceholder[]) {
        state.groupTypePlaceholders = val
      },
      SET_SELECTED_GROUP_TYPE_PLACEHOLDER(state, val: string | null) {
        state.selectedGroupTypePlaceholder = val
      },
      SET_IS_BUTTON_DISABLED(state, val: boolean) {
        state.isButtonDisabled = val
      },
      INIT_TEMPLATE(state) {
        state.template = {
          name: '',
          body: '',
          program_id: store.getters['verticalMenu/getDefaultProgram'],
          group_type_id: '',
        }
      },
      SET_NEW_NAME(state, val: string) {
        state.new_name = val
      },
    },
    actions: {
      async saveAsGroupListTemplate({ state }) {
        try {
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(true)

          const queryParams = {
            id: state.template.id,
            name: state.new_name,
            body: state.template.body,
            program_id: state.template.program_id,
            group_type_id: state.template.group_type_id,
          }

          const response = await createGroupListTemplate(queryParams)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Letter template was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
          appGroupListTemplate.mutations.SET_NEW_NAME('')
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Letter template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async createGroupListTemplate({ state }) {
        try {
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(true)
          const response = await createGroupListTemplate(state.template)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Letter template was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Letter template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async fetchGroupListTemplate(ctx, id) {
        try {
          appGroupListTemplate.mutations.SET_IS_LOADING(true)
          const response = await fetchGroupListTemplate(id)
          appGroupListTemplate.mutations.SET_TEMPLATE(response.data.data)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Group list template item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appGroupListTemplate.mutations.SET_IS_LOADING(false)
        }
      },
      async updateTemplate({ state }) {
        try {
          const updatedTemplate = {
            id: state.template.id,
            name: state.template.name,
            body: state.template.body,
            program_id: state.template.program_id,
            group_type_id: state.template.group_type_id,
          }

          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(true)

          await updateGroupListTemplate(updatedTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Group list template was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating Group list template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appGroupListTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async fetchGroupTypePlaceholders({ state }) {
        const response = await fetchGroupTypePlaceholders({
          group_type_id: state.template.group_type_id,
        })
        const groupTypePlaceholders = response.data.data.map((item: { value: any; label: any }) => ({
          value: `{{${item.value}}}`,
          label: item.label,
        }))
        appGroupListTemplate.mutations.SET_GROUP_TYPE_PLACEHOLDERS(groupTypePlaceholders)
      },
      async fetchGroupTypes({ rootGetters }) {
        const response = await fetchGroupTypesForSelect({ programId: rootGetters['verticalMenu/getDefaultProgram'] })
        const groupTypeOptions = response.data.data
        appGroupListTemplate.mutations.SET_GROUP_TYPE_OPTIONS(groupTypeOptions)
      },
    },
  },
)

appGroupListTemplate.register(store)
